/*Styling to hide the scrollbar for Chrome, Safari and Opera*/
body::-webkit-scrollbar {
    display: none;
}

/*Styling to hide the scrollbar for Internet Explorer, Edge and Firefox*/
body {
    overflow-x: hidden;
    /*Internet Explorer and Edge*/
    -ms-overflow-style: none;
    /*Firefox*/
    scrollbar-width: none;

    /*background-color: #f0d8bb;*/
}

@media screen and (max-width: 768px) {
    body {
        overflow-x: hidden !important;
    }

    .container {
        max-width: 100% !important;
        overflow-x: hidden !important;
    }
}

html body {
    overflow-x: hidden;
    scroll-behavior: smooth;
}
